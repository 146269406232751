import { createAction } from 'typesafe-actions';

import { IConfirmDialog } from '../components/backoffice/dialogs/ConfirmDialog/types';

enum Types {
  CONFIRM_DIALOG = '@@app/CONFIRM_DIALOG',
  CONFIRM_DIALOG_CLOSE = '@@app/CONFIRM_DIALOG_CLOSE',
}

export const confirmDialog = createAction(
  Types.CONFIRM_DIALOG,
  (props: IConfirmDialog) => props
)();
export const confirmDialogClose = createAction(
  Types.CONFIRM_DIALOG_CLOSE,
  (action) => () => action()
)();
