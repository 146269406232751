import { ActionType, getType } from 'typesafe-actions';

import * as types from 'actions/app';

import { IConfirmDialog } from '../components/backoffice/dialogs/ConfirmDialog/types';

type Actions = ActionType<typeof types>;

interface IConfirmDialogState {
  data?: IConfirmDialog;
  open: boolean;
}

export interface IAppState {
  readonly confirmDialog: IConfirmDialogState;
}

const initialState: IAppState = {
  confirmDialog: {
    open: false,
  },
};

export default (
  state: IAppState = initialState,
  action: Actions
): IAppState => {
  switch (action.type) {
    case getType(types.confirmDialog): {
      return {
        ...state,
        confirmDialog: {
          data: action.payload,
          open: true,
        },
      };
    }

    case getType(types.confirmDialogClose): {
      return {
        ...state,
        confirmDialog: {
          ...state.confirmDialog,
          open: false,
        },
      };
    }

    default:
      return state;
  }
};
